import { combineReducers } from 'redux'
import majorReducers from '../reducers'

/**
 * react-redux-starter-kit version
 * https://github.com/davezuko/react-redux-starter-kit/blob/master/src/store/reducers.js
 */
export const makeRootReducer = asyncReducers =>
  combineReducers({ ...majorReducers, ...asyncReducers })

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return
  store.asyncReducers[key] = reducer // eslint-disable-line no-param-reassign
  store.replaceReducer(makeRootReducer(store.asyncReducers))
}

/**
 * Jimmy Shen version
 * https://medium.com/@jimmy_shen/inject-reducer-arbitrarily-rather-than-top-level-for-redux-store-to-replace-reducer-fdc1060a6a7
 */
/*
const replaceAsyncReducers = (rootReducers, keys, reducer) => {
  const key = keys.shift();
  if (keys.length === 0) {
    rootReducers[key] = reducer;
    return;
  }
  if (rootReducers[key] === undefined) rootReducers[key] = {};
  const nextRootReducers = rootReducers[key];
  return replaceAsyncReducers(nextRootReducers, keys, reducer);
};

const combineAsyncReducers = asyncReducers => {
  if (typeof asyncReducers !== 'object') return asyncReducers;
  const combineReducerObject = Object.keys(asyncReducers)
    .filter(key => Object.prototype.hasOwnProperty.call(asyncReducers, key))
    .reduce((prev, key) => {
      let next;
      const value = asyncReducers[key];
      if (typeof value === 'object') {
        next = combineAsyncReducers(value);
      } else if (typeof value === 'function') {
        next = value;
      }
      return { prev, [key]: next };
    }, {});
  return combineReducers(combineReducerObject);
};

export const makeRootReducer = (asyncReducers = {}) => {
  const newAsyncReducers = Object.keys(asyncReducers)
    .filter(key => Object.prototype.hasOwnProperty.call(asyncReducers, key))
    .reduce(
      (prev, key) => ({
        ...prev,
        [key]: combineAsyncReducers(asyncReducers[key]),
      }),
      {},
    );
  console.info(newAsyncReducers);
  return combineReducers({
    ...majorReducers,
    ...asyncReducers,
  });
};

export const injectReducer = (store, { key, reducer }) => {
  const keys = key.split('.');
  replaceAsyncReducers(store.asyncReducers, keys, reducer);

  store.replaceReducer(makeRootReducer(store.asyncReducers));
};
*/

export default makeRootReducer
