import { combineActions, handleActions } from 'redux-actions'
import {
  dismissSystemModal,
  setSuccessMessage,
  setSystemError,
} from 'actions/modal'

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = []

export default handleActions(
  {
    [combineActions(setSystemError, setSuccessMessage)]: (
      state,
      { payload },
    ) => [...state, payload],
    [dismissSystemModal]: (state, { payload: { id } }) =>
      state.filter(modal => modal.id !== id),
  },
  initialState,
)
