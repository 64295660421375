import { createAction } from 'redux-actions'
import BaseApi from './api/BaseApi'

export const LOAD_CONF_SUCCESS = 'LOAD_CONF_SUCCESS'
export const LOAD_CONF_FAILURE = 'LOAD_CONF_FAILURE'

const getSystemConfig = () => async dispatch => {
  try {
    const api = new BaseApi().create()
    const rslt = await dispatch(
      api({
        url: '/api/system/maintenance',
        loadingTarget: 'system',
      }),
    )
    dispatch(createAction(LOAD_CONF_SUCCESS)(rslt))
  } catch (error) {
    dispatch(createAction(LOAD_CONF_FAILURE)(error))
  }
}

export default getSystemConfig
