/**
 * Report errors to remote server
 */
import Raven from 'raven-js'

class Logger {
  isReady = false

  setup() {
    if (__SENTRY__) {
      Raven.config(__SENTRY__, {
        release: __RELEASE__,
        environment: process.env.NODE_ENV,
        tags: {
          version: __VERSION__,
        },
      }).install()
      this.isReady = true
    }
  }

  /**
   * Wrap code within a context so it can capture errors
   * @param {function} func - The callback to be immediately executed within the context
   */
  context(func) {
    if (!this.isReady) return func()
    return Raven.context(func)
  }

  /**
   * Similar to console.info
   * @param {string} message - identify the situation
   * @param {object=} data - passing aditional data for more info
   */
  info(message, data = {}) {
    if (!this.isReady) return console.info(message)
    return Raven.captureMessage(message, { level: 'info', extra: data })
  }

  /**
   * Similar to console.warn
   * @param {string} message - identify the situation
   * @param {object=} data - passing aditional data for more info
   */
  warn(message, data = {}) {
    if (!this.isReady) return console.warn(message)
    return Raven.captureMessage(message, { level: 'warning', extra: data })
  }

  /**
   * Similar to console.error
   * @param {Error} error - error object
   * @param {object=} data - passing aditional data for more info
   */
  error(error, data = {}) {
    if (!this.isReady) return console.error(error)
    return Raven.captureException(error, { extra: data })
  }
}

export default new Logger()
