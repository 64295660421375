import { handleActions } from 'redux-actions'
import { LOAD_CONF_SUCCESS } from 'actions/system'

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {}

export default handleActions(
  {
    [LOAD_CONF_SUCCESS]: (state, { payload }) => payload,
    [LOAD_CONF_SUCCESS]: () => ({
      isMaintenance: false,
    }),
  },
  initialState,
)
